import { IconProps } from "@chakra-ui/core";
import { Icon } from "@chakra-ui/react";

export const ArrowRightSimpleIcon = (props: IconProps) => {
  return (
    <Icon width="8px" height="14px" viewBox="0 0 8 14" fill="none" {...(props as any)}>
      <path d="M1 13L7 7L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};
