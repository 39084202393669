import { Icon, IconProps } from "@chakra-ui/react";

export const ShareIcon = (props: IconProps) => {
  return (
    <Icon
      width="16px"
      height="18px"
      viewBox="0 0 16 18"
      fill="none"
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.718 5.6391C13.9247 5.6391 14.906 4.62744 14.906 3.38346C14.906 2.13948 13.9247 1.12782 12.718 1.12782C11.5113 1.12782 10.5299 2.13948 10.5299 3.38346C10.5299 3.58083 10.5627 3.76917 10.6087 3.95188C10.7039 4.32632 10.8866 4.66466 11.1393 4.9376C11.5375 5.36729 12.0965 5.6391 12.718 5.6391ZM3.28205 11.2782C3.8969 11.2782 4.45046 11.0143 4.84868 10.5925C5.10359 10.3218 5.28629 9.98459 5.38475 9.61015C5.43508 9.42181 5.47009 9.22782 5.47009 9.02256C5.47009 8.79587 5.42742 8.57932 5.36615 8.37293C5.25784 8.00301 5.0664 7.6703 4.80273 7.40639C4.40889 7.01278 3.87392 6.76692 3.28205 6.76692C2.07536 6.76692 1.09402 7.77858 1.09402 9.02256C1.09402 10.2665 2.07536 11.2782 3.28205 11.2782ZM12.718 16.8722C13.9247 16.8722 14.906 15.8605 14.906 14.6165C14.906 13.3726 13.9247 12.3609 12.718 12.3609C12.1042 12.3609 11.5517 12.6237 11.1546 13.0432C10.8986 13.3139 10.7148 13.6511 10.6163 14.0256C10.566 14.215 10.5299 14.4102 10.5299 14.6165C10.5299 15.8605 11.5113 16.8722 12.718 16.8722ZM12.718 6.76692C11.6918 6.76692 10.7848 6.26955 10.1831 5.50601L6.33327 7.79775C6.47768 8.17896 6.56411 8.58949 6.56411 9.02256C6.56411 9.4331 6.48205 9.82106 6.35186 10.1853L10.1984 12.4759C10.8012 11.7237 11.7005 11.2331 12.718 11.2331C14.5275 11.2331 16 12.7511 16 14.6166C16 16.482 14.5275 18 12.718 18C10.9085 18 9.4359 16.482 9.4359 14.6165C9.4359 14.2049 9.51796 13.8147 9.64924 13.4504L5.80376 11.1609C5.20205 11.9143 4.30168 12.406 3.28205 12.406C1.47255 12.406 0 10.888 0 9.02256C0 7.15715 1.47255 5.6391 3.28205 5.6391C4.2787 5.6391 5.16267 6.1094 5.76438 6.83459L9.64158 4.52706C9.51577 4.16729 9.4359 3.78609 9.4359 3.38346C9.4359 1.51805 10.9085 0 12.718 0C14.5275 0 16 1.51805 16 3.38346C16 5.24887 14.5275 6.76692 12.718 6.76692Z"
        fill="currentColor"
      />
    </Icon>
  );
};
